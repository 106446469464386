import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import { useState } from "react";
import $ from "jquery";
import loader from ".././loader.gif";
import ScrollAnimation from "react-animate-on-scroll";
import Footer from "../components/Footer";
import './contact.css'

function Contact() {
  const [result, setResult] = useState("");
  $("#loader").hide();

  const handleSumbit = (e) => {
    $("#loader").show();
    e.preventDefault();
    // document.getElementById("#form").reset();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        $("#loader").hide();
        document.getElementById("form1").reset();
        setResult(data);
      },
    });
  };
  return (
    <>
      <div className="site-main" id="contactid">
        <section className="ttm-row pt-85 res-991-pt-45 pb-50 res-991-pb-50 clearfix">
          <div className="container">
            <div className="row">
              <div className="col-md-10 m-auto">
                <div className="section-title title-style-center_text">
                  <div className="title-header">
                  <h2 className="title-contact" style={{fontFamily:"MyFont"}}>Discover the art of interior transformation</h2>
                  <ScrollAnimation animateIn="zoomIn">
                    <hr style={{ width: "20%" }}></hr>
                  </ScrollAnimation>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </section>
       
      </div>
      <Footer />
    </>
  );
}

export default Contact;
