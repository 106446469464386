import React from "react";
import Slider from "../components/Slider";
import './Home.css';
import Counter from "./Counter";
import Footer from "../components/Footer";



// import loadscript from "../loadscript";

function Home() {

  return (
    <>
      <Slider />
      <Counter />
     <Footer />
    </>
  );
}

export default Home;
