import React from "react";
import { styled } from "@mui/material/styles";
import AddIcon from '@mui/icons-material/Add';
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import "./Faq.css";
import Team from "./Team";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderTop: "1px solid #E8CC90",
  borderBottom: "1px solid #E8CC90",
  "&:not(:last-child)": {
    borderBottom: 0,
    backgroundColor:"transparent",
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<AddIcon sx={{ fontSize: "2rem",color:'white' }} />}
    
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, 0)",
  // flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(45deg)",
    
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  color:"white",
  backgroundColor:"transparent",
  borderTop: "1px solid #E8CC90",
  borderBottom: "1px solid #E8CC90",
}));
function Faq() {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <section className="welcome-section clearfix pb-30">
        <div className="container">
          <div className="row">
            <div className="col-md-6 m-auto">
              <div className="section-title title-style-center_text">
                <div className="title-header">
                  <h2 className="title mb-0 themeColor">Expolre us</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-10">
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
                
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Typography style={{ color: "#E8CC90" }}>About US</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <p>
                    At ICON Projects Inspace Pvt. Ltd. (IPIPL), we are dedicated to cutting-edge design, bringing our clients' visions to life through innovative, bespoke solutions. Our approach is rooted in a strong commitment to the highest standards of quality and meticulous attention to detail. We take pride in delivering projects that are not only aesthetically striking but also functional and time-efficient. Our portfolio spans across India and international markets, covering a diverse range of projects, from interior design to architectural services.
                    </p>
                    <p>
                    Led by the visionary Amit Porwal, an award-winning designer with numerous accolades to his name, IPIPL has established itself as a trusted name in the industry since 2003. Porwal’s achievements include prestigious awards such as The Ace of Space 2023, The D/List, Smart Space Award, FOAID, and Grohe India Bath Design Awards. These honors reflect his unwavering dedication to excellence and innovation.
                    </p>
                    <p>
                    At IPIPL, we focus on delivering trendsetting solutions that are tailored to the unique needs of each client, ensuring that every project is a true reflection of their identity and vision. From conceptual design to seamless execution, our expertise, creativity, and passion drive us to create extraordinary spaces.
                    </p>
                    <p>Our extensive experience allows us to overcome a wide range of design challenges, offering innovative architectural and interior solutions for both residential and commercial spaces. We believe every space has the potential to be extraordinary, and we are committed to turning that potential into reality.</p>
                    <p>Trust us to bring your design aspirations to life—because if you have the space, we have the solution.</p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  aria-controls="panel2d-content"
                  id="panel2d-header"
                >
                  <Typography style={{ color: "#E8CC90" }}>Process</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget. Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit. Suspendisse malesuada lacus ex, sit amet
                    blandit leo lobortis eget.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
                style={{backgroundColor:"transparent"}}
              >
                <AccordionSummary
                  aria-controls="panel3d-content"
                  id="panel3d-header"
                >
                  <Typography style={{ color: "#E8CC90" }}>Team</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Team />
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Faq;
